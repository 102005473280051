export default {
  en: 'Bahasa Inggris',
  home: {
    NewPortal: 'Portal Baru',
    SecureIBPortal: 'Portal IB Aman',
    DASHBOARD: 'DASBOR / PAPAN INSTRUMEN ',
    MARKETING_TOOLS: 'ALAT PEMASARAN',
    IB_REPORT: 'LAPORAN IB',
    REBATE_REPORT: 'LAPORAN RABAT',
    IB_ACCOUNTS: 'AKUN IB',
    PAYMENT_HISTORY: 'RIWAYAT TRANSAKSI',
    IB_FAQ: 'TANYA JAWAB IB',
    RebateAccountNumber: 'Nomor akun rabat',
    totalBalance: 'saldo total',
    totalCommission: 'komisi total',
    APPLY_FOR_A_REBATE: 'TERAPKAN',
    availableBalance: 'saldo tersedia',
    WITHDRAW_REBATE: 'PENARIKAN',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFER',
    MonthtoDate: 'Bulan sampai saat ini',
    UPDATE: 'MEMPERBARUI',
    REBATE: 'RABAT',
    TOTAL_VOLUME: 'VOLUME TOTAL',
    NET_FUNDING: 'PENDANAAN BERSIH',
    DEPOSITS: 'DEPOSIT / SETORAN',
    WITHDRAW: 'PENARIKAN',
    OPEND_ACCOUNT: 'PEMBUKAAN AKUN',
    PERFORMANCE: 'KINERJA',
    OpendAccount: 'AKUN DIBUKA',
    select_ib: 'Silahkan pilih akun IB',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMEN YANG PALING SERING DIPERDAGANGKAN',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'AKUN DENGAN KINERJA TERATAS BULAN INI',
    RECENTLY_OPENED_ACCOUNTS: 'AKUN YANG BARU DIBUKA',
    ACCOUNT_MANAGER: 'LAYANAN PELANGGAN',
    Account: 'Akun',
    Name: 'Nama',
    Volume: 'Volume',
    Rebate: 'Rabat',
    Balance: 'Saldo',
    ContactClient: 'Hubungi Klien',
    YouCannotAskForCommission: 'Anda tidak dapat meminta komisi',
    applicationFailedPleaseTryAgain: 'Permohonan gagal, silahkan coba lagi',
    applyRebateSuccessTip:
      'Selamat, kami telah menerima permintaan rabat Anda. | {amount} akan segera ditransfer ke Akun rabat {account} . | Setelah diproses, Anda akan bisa menarik atau mentransfer dana Anda.',
    HOVER:
      'Rabat hari sebelumnya akan dihitung dan diproses antara jam 09:00-10.00 AEST setiap hari (06:00-08:00 GMT+7-Jakarta Time)',
    blacklist: 'Akun ini tidak bisa memperoleh rabat saat ini, harap hubungi manajer akun Anda.',
    ACCOUNTS_PROFIT:
      'Total historis keuntungan/kerugian semua akun perdagangan dengan akun IB ini. Memuat mungkin memerlukan waktu beberapa saat.',
    PROFIT: 'AKUN LABA',
    reload: 'Muat ulang',
  },
  liveChat: { header: 'Berbicara langsung ' },
  contactUs: {
    click: 'Klik',
    assist: 'untuk bantuan',
    header: 'HUBUNGI KAMI',
    followUs: 'IKUTI KAMI',
    tip: 'Tim dukungan pelanggan multibahasa profesional {platform} siap untuk mendampingi dan membantu anda dalam menyediakan pengalaman perdagangan yang tidak tertandingi.',
    chatNow: 'Mari kita ngobrol sekarang',
    globalOffice: 'Kantor Global',
  },
  ibAccounts: {
    header: 'AKUN IB',
    ibAccount: 'Akun IB',
    subIbs: 'Akun Sub IB',
    tradinglist: 'Daftar Perdagangan',
    openlist: 'Daftar Terbuka',
    closedlist: 'Daftar Tertutup',
    tradinglistColumn: {
      ticketid: 'ID Tiket',
      opentime: 'Waktu Buka',
      type: 'Jenis',
      volume: 'Volume',
      item: 'Barang',
      openprice: 'Buka Harga',
      closeprice: 'Tutup Harga',
      closetime: 'Tutup Waktu',
      commission: 'Komisi',
      profit: 'Laba',
      buy: 'Membeli',
      sell: 'Menjual',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Pilih Kartu Kredit',
      selectBA: 'Pilih Akun Bank',
      anotherCC: 'Masukan kartu kredit secara manual',
      anotherBA: 'Tambahkan akun bank',
      remember: 'Ingat akun saya',
      verificationCode:
        'Kode verifikasi telah dikirimkan ke {email}. Harap hubungi{supportEmail} bila Anda membutuhkan bantuan lebih lanjut',
      fetchTradeAccFailed: 'Pengambilan Akun Perdagangan gagal',
      failed: 'Penarikan gagal. Silakan coba lagi nanti',
      tokenExpired: 'Token kedaluwarsa',
    },
    alert:
      'Berdasarkan peraturan AML, metode yang anda gunakan untuk melakukan penarikan dana harus sama dengan metode yang anda gunakan untuk melakukan penyetoran. Jika jumlah penarikan anda kurang dari 100 unit dari mata uang basis akun anda, maka anda akan dikenai potongan sebesar 20 unit sebagai biaya penanganan dari bank',
    NetellerChargeAlert:
      'Neteller membebankan biaya transaksi {percentageFee}% untuk setiap penarikan.',
    FasaPayChargeAlert: 'FasaPay mengenakan biaya transaksi 0,5% untuk setiap penarikan.',
    SkrillChargeAlert:
      'Skrill membebankan biaya transaksi {percentageFee}% untuk setiap penarikan.',
    cryptoAlert:
      'Harap dicatat bahwa kami tidak dapat melakukan deposit atau penarikan melalui BUSDT, pastikan alamat dan Mata Uang Kripto Anda sesuai dengan alamat rantai dan mata uang yang kami terima atau Anda bisa kehilangan dana Anda.',
    REBATE_WITHDRAW_REQUEST: 'PERMINTAAN PENARIKAN RABAT',
    PleaseSelectDesiredWithdrawMethod: 'Silahkan pilih metode penarikan yang diinginkan',
    BankName: 'Nama Bank',
    BankAccountsName: 'Nama Akun Bank',
    FasaPayAccountName: 'Nama Akun Fasapay',
    AustralianBankName: 'Nama Bank Australia',
    BankAccountNumber: 'Nomor Akun Bank',
    FasaPayAccountNumber: 'Nomor Akun Fasapay',
    BankAccountBeneficiary: 'Akun Bank Penerima',
    BSB: 'BSB',
    skrillEmail: 'Email Skrill ',
    SticpayEmail: 'Email SticPay',
    NetellerEmail: 'Email Neteller',
    PerfectMoneyEmail: 'Email Perfect Money',
    BitwalletEmail: 'Email Bitwallet',
    BitcoinEmail: 'Alamat Dompet Bitcoin',
    USDTEmail: 'Alamat Dompet Tether',
    BankBranch: 'Cabang Bank',
    Province: 'Provinsi',
    City: 'Kota',
    BankAddress: 'Alamat Bank',
    bankCity: 'Kota Bank',
    bankProvince: 'Provinsi Bank',
    AccountNumberIBAN: 'Nomor Akun / IBAN',
    BankBeneficiaryName: 'Nama Bank Penerima',
    BankAccountBeneficiaryName: 'Nama Akun Bank Penerima',
    AccountHoldersAddress: 'Alamat Pemilik Akun',
    SelectWithdrawCard: 'Pilih Kartu Penarikan Anda',
    Swift: 'Swift',
    ifscCode: 'Kode IFSC',
    SwiftAud: 'Swift (Akun Non AUD)',
    ABA_SortCodeABA: 'Kode ABA/Sort',
    ImportantNotes: 'Catatan Penting',
    YourPaymentGreaterThanAvailableBalance: 'Pembayaran anda melebihi saldo yang tersedia',
    CannotUseMoreThanTwoDecimalValues:
      'Anda tidak dapat menggunakan bilangan dan bilangan negatif yang melebihi dua angka desimal',
    YourPaymentIsBeingProcessed: 'Permohonan penarikan anda telah diajukan',
    Itcannotbe0: 'Tidak boleh 0',
    noCardWarn:
      'Anda tidak memiliki kartu yang tersedia untuk melakukan penarikan, silahkan tautkan kartu anda di dalam Detail Penarikan terlebih dahulu',
    amtLarger:
      'Jumlah tidak boleh nol dan harus lebih besar dari atau sama dengan {minLimit} {currency}',
    blackList:
      'Sayangnya saat ini Anda tidak dapat mengirimkan permintaan penarikan. Harap hubungi {supportEmail} untuk informasi lebih lanjut',
    status: {
      withdrawalSubmitted: 'Diajukan / Dikirimkan',
      withdrawalCanceled: 'Dibatalkan',
      withdrawalAuditing: 'Sedang diproses',
      withdrawalRejected: 'Ditolak',
      withdrawalAccepted: 'Sedang diproses',
      withdrawalPayFailed: 'Gagal',
      withdrawalSuccess: 'Diproses',
      withdrawalFailed: 'Gagal',
      withdrawalPending: 'Tertunda',
      withdrawalPartialFailed: 'Gagal',
      transferSubmitted: 'Sedang diproses',
      transferSuccess: 'Diproses',
      transferRejected: 'Ditolak',
      transferFailed: 'Gagal',
      transferProcessing: 'Sedang diproses',
      withdrawalReversedFailed: 'Gagal Terbalik',
      withdrawalPartialSuccess: 'Keberhasilan Sebagian',
    },
    formErrorMsg: {
      bankName: 'Nama Bank harus diisi',
      bsb: 'BSB harus diisi',
      beneName: 'Nama Bank Penerima harus diisi',
      accNum: 'Nomor Akun Bank harus diisi',
      swift: 'Swift harus diisi',
      bankAddress: 'Alamat Bank harus diisi',
      holderAddress: 'Alamat Penerima harus diisi',
      amt: 'Jumlah harus diisi',
      firstSixDigits: 'Silahkan isi 6 digit pertama',
      lastFourDigits: 'Silahkan isi 4 digit pertama',
      branchName: 'Nama cabang harus diisi',
      email: 'Email harus diisi',
      accName: 'Nama akun harus diisi',
      province: 'Provinsi Bank harus diisi',
      city: 'Kota Bank harus diisi',
      bankCard: 'Silahkan pilih kartu',
      emailFormat: 'Silahkan isikan alamat email yang tepat',
      amt0: 'Jumlah tidak boleh 0',
      ifscCode: 'Kode IFSC harus diisi',
      bitcoin: 'Alamat Dompet Bitcoin diperlukan',
      addressLength: 'Alamat wallet bitcoin harus di antara 26 dan 42 karakter',
      usdt: 'Alamat USDT Wallet diperlukan',
      beneficiaryNameReq: 'Nama Penerima Bank diperlukan',
    },
    transfer: {
      info1:
        '{platform} akan membebaskan biaya bank untuk penarikan pertama di setiap bulan. Penarikan tambahan dalam bulan yang sama akan dikenai biaya bank sebesar 20 unit mata uang penarikan Anda (Untuk USC/HKD/JPY, biayanya setara dengan 20 USD)',
      info2:
        'Pastikan Nama Pemilik Rekening Bank diisi sesuai dengan paspor (tidak boleh disingkat, isi nama lengkap sesuai yang di awal pada rekening koran bank)',
      info3:
        'Rekening bank diutamakan dalam format IBAN, bila Anda tidak berasal dari kawasan Uni Eropa, harap diisi sama persis dengan rekening koran bank',
      label: {
        upload: 'Harap unggah salinan rekening koran anda (terkini hingga 3 bulan terakhir)',
        upload2:
          'Pastikan tanggal laporan atau tanggal transaksi terakhir berada dalam rentang bulan ini hingga tiga bulan yang lalu. Jika melebihi batas waktu tersebut, silakan unggah ulang laporan.',
      },
      formValidation: { upload: 'Harap unggah rekening koran' },
      minAmount: 'Jumlah penarikan minimum adalah 100 {currency}',
    },
    transitNumber: 'Nomor Transit',
    institutionNumber: 'Nomor Institusi',
    beneficiaryName: 'Nama Penerima',
    LBTchannelsNote:
      'Catatan:\n  Nama Rekening Bank/Nama Penerima Manfaat harus sesuai dengan nama di rekening\n  bank Anda.',
  },
  security: {
    contactSupport:
      'Tidak dapat mengakses metode autentikasi? Silakan hubungi {contact_email} atau Livechat',
    securityAuthenticator: 'Keamanan Autentikasi',
    verificationRequired: 'Silakan masukkan kode verifikasi',
    resend: 'Kirim ulang',
    authenticatorTooltip: 'Kode verifikasi aplikasi Autentikator akan diperbarui setiap 30 detik',
    verifyPreEmailNote: 'Kode akan dikirimkan ke {email}',
    verifyPostEmailNote: 'Kode telah dikirimkan ke {email}',
    enableNow: 'Aktifkan sekarang',
    verificationTitle: 'Verifikasi diperlukan',
    verificationDesc: 'Harap aktifkan Security Authenticator APP sebelum melanjutkan.',
  },
  rebate: { status: { processed: 'Diproses', processing: 'Sedang diproses', rejected: 'Ditolak' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'PERMOHONAN TRANSFER RABAT',
    transferAmountIsGreaterThanAvailableBalance:
      'Jumlah transfer anda melebihi jumlah saldo yang tersedia',
    yourRebateTransferHasBeenProcessed: 'Transfer rabat anda sudah diajukan',
    PleaseEnterTransferInformation: 'Silahkan isi dengan informasi transfer yang tepat',
    tradingAccount: 'Akun Perdagangan',
    rebateAccount: 'Akun Rabat',
    account: 'Akun',
    blackList:
      'Akun ini telah dibatasi untuk melakukan transfer antar akun, harap hubungi {supportEmail}',
  },
  common: {
    liveChat: { desc: 'Butuh Bantuan? Berbicara Sekarang' },
    lang: {
      english: 'Bahasa Inggris',
      chinese: 'Bahasa Mandarin',
      french: 'Bahasa Perancis',
      thai: 'Bahasa Thailand',
      german: 'Bahasa Jerman',
      spanish: 'Bahasa Spanyol',
      malay: 'Bahasa Melayu',
      viet: 'Bahasa Vietnam',
      indo: 'Bahasa Indonesia',
      arabic: 'Bahasa Arab',
    },
    button: {
      iKnow: 'Saya mengerti',
      confirmCamelCase: 'Konfirmasi',
      confirm: 'KONFIRMASI',
      submit: 'Kirim',
      cancel: 'BATAL',
      sendCode: 'Kirim Kode',
      resendCode: 'Kirim Ulang Kode',
      ok: 'OK',
    },
    withdrawChannel: {
      limitTitle: 'Withdraw dana tidak dapat dilakukan sementara waktu',
      limitPassword:
        'Dikarenakan perubahan password masuk Anda, withdraw dana tidak dapat dilakukan sementara waktu selama {day}hari karena alasan keamanan. Pembatasan ini diharapkan akan berakhir dalam {hour} jam.',
      limitUserLogin:
        'Dikarenakan perubahan alamat email/nomor telepon masuk Anda, withdraw dana tidak dapat dilakukan sementara waktu selama {day} hari. Pembatasan ini diharapkan akan berakhir dalam {hour} jam.',
      limitSecurityAuthenticator:
        'Karena modifikasi aplikasi Authenticator Keamanan Anda, penarikan tidak didukung sementara waktu selama {day} hari untuk memastikan keamanan akun Anda. Pembatasan ini diperkirakan akan dicabut dalam {hour} jam.',
      banktransfer: 'Transfer Bank Internasional',
      aus: 'Transfer Bank - Australia',
      int: 'Transfer Bank - Internasional',
      chn: 'Transfer Bank - China',
      skrill: 'Skrill ',
      skrill_neteller: 'Skrill/Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'UnionPay Transfer',
      fasapay: 'Fasapay',
      creditcard: 'Kartu Kredit',
      bpay: 'Bpay',
      creditdebitcard: 'Kartu Kredit/Debit',
      bankwiretransferinternational: 'Wire Transfer Bank (Internasional)',
      banktransferaustralia: 'Transfer Bank (Australia)',
      banktransferinternational: 'Transfer Bank Internasional',
      banktransferchina: 'Transfer Bank (Internasional)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transfer Antar Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Perbankan Elektronik (Thailand)',
      internetbankingnigeria: 'Perbankan Elektronik (Nigeria)',
      internetbankingvietnam: 'Perbankan Elektronik (Vietnam)',
      internetbankingmalaysia: 'Perbankan Elektronik (Malaysia)',
      internetbankingindonesia: 'Perbankan Elektronik (Indonesia)',
      internetbankingkorea: 'Perbankan Elektronik (Korea)',
      internetbankingindia: 'Perbankan Elektronik (India)',
      internetbankingphilippine: 'Perbankan Elektronik (Filipina)',
      internetbankingsouthafrica: 'Perbankan Elektronik (Afrika Selatan)',
      internetbankinguganda: 'Perbankan Elektronik (Uganda)',
      internetbankingrwanda: 'Perbankan Elektronik (Rwanda)',
      internetbankingzambia: 'Perbankan Elektronik (Zambia)',
      internetbankingcongo: 'Perbankan Elektronik (Kongo)',
      internetbankingcameroon: 'Perbankan Elektronik (Kamerun)',
      internetbankingburundi: 'Perbankan Elektronik (Burundi)',
      internetbankingkenya: 'Perbankan Elektronik (Kenya)',
      internetbankingghana: 'Perbankan Elektronik (Ghana)',
      internetbankingtanzania: 'Perbankan Elektronik (Tanzania)',
      internetbankingcanada: 'Perbankan Elektronik (Kanada)',
      internetbankingbrazil: 'Perbankan Elektronik (Brazil)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transfer Bank Vietnam',
      nigeriabanktransfer: 'Transfer Bank Nigeria',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: 'Wire Transfer Bank (Australia)',
      thailandinstantbankwiretransfer: 'Wire Transfer Bank Thailand Instan',
      malaysiainstantbankwiretransfer: 'Transfer Bank Malaysia Instan',
      banktransferbpaypolipay: 'Transfer Bank/Bpay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      cryptocurrencyethcps: 'Cryptocurrrency-ETH',
      cryptocurrencyusdccps: 'Cryptocurrrency-USDC(ERC-20)',
      nigeriainstantbankwiretransfer: 'Transfer Bank Nigeria',
      vietnaminstantbankwiretransfer: 'Wire Transfer Bank Vietnam Instan',
      indonesiainstantbankwiretransfer: 'Transfer Bank Indonesia Instan',
      philippinesinstantbankwiretransfer: 'Transfer Bank Instan Filipina',
      southafricainstantbankwiretransfer: 'Wire Transfer Bank Instan Afrika Selatan',
      indiainstantbanktransfer: 'Wire Transfer Bank Instan India',
      indiabanktransfer: 'Transfer Bank India',
      southkoreabanktransfer: 'Transfer Bank Lokal Korea Selatan',
      ugandabanktransfer: 'Transfer Bank Lokal Uganda',
      rwandabanktransfer: 'Transfer Bank Lokal Rwanda',
      zambiabanktransfer: 'Transfer Bank Lokal Zambia',
      congobanktransfer: 'Transfer Bank Lokal Kongo',
      cameroonbanktransfer: 'Transfer Bank Lokal Kamerun',
      burundibanktransfer: 'Transfer Bank Lokal Burundi',
      kenyabanktransfer: 'Transfer Bank Lokal Kenya',
      ghanabanktransfer: 'Transfer Bank Lokal Ghana',
      tanzaniabanktransfer: 'Transfer Bank Lokal Tanzania',
      brazilbanktransfer: 'Transfer Bank Brasil',
      banktransferbpaypolipayaustralia: 'Transfer Bank /Bpay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Transfer Bank/Bpay/PoliPay (Internasional)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Transfer Bank Lokal Kanada',
      cryptobitcoin: 'Cyrpto-Bitcoin',
      cryptotetheromni: 'Crypto-Tether(OMNI)',
      cryptotethererc20: 'Cyrpto-Tether(ERC20)',
      astropay: 'Astropay',
      vload: 'Vload',
      sticpay: 'SticPay',
      transfer: 'Transfer ke akun',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'MATA UANG KRIPTO',
      LOCAL_TRANSFER: 'TRANSFER LOKAL',
      japanbanktransfer: 'Transfer Bank Jepang',
      internetbankingmexico: 'Perbankan Elektronik (Meksiko)',
      internetbankinguae: 'Perbankan Internet (UAE)',
      ewallet: 'Dompet Elektronik',
      internetbankingtaiwan: 'Perbankan Internet (Taiwan)',
      internetbankingeu: 'Perbankan Internet (EU)',
      cryptousdttrc20: 'Cyrpto-USDT(TRC20)',
      momopay: 'Momopay',
      uaebanktransfer: 'Transfer bank Uni Emirat Arab',
    },
    country: { international: 'Internasional', australia: 'Australia' },
    field: {
      country_region: 'Negara/Wilayah',
      address: 'Alamat',
      phone: 'Nomor Telepon',
      nat: 'Kewarganegaraan',
      country: 'Negara tempat tinggal',
      save: 'Investasi',
      annIncome: 'Pendapatan Tahunan',
      emailAdd: 'Alamat email',
      yes: 'Ya',
      no: 'Tidak',
      enterVerificationCode: 'Masukkan Kode Verifikasi',
      ebuyAccName: 'Nama Rekening EBuy',
      ebuyEmailAddress: 'Alamat Email EBuy',
      skrillAccName: 'Nama Akun Skrill',
      skrillEmailAddress: 'Alamat Email Skrill',
      netellerAccName: 'Nama Akun Neteller',
      netellerEmailAddress: 'Alamat Email Neteller',
      imptNotes: 'Catatan penting',
      paymentAccName: '{name} Nama Akun',
      paymentAccNum: '{name} Nomor Akun',
      documentType: 'Tipe dokumen',
      accDigit: 'Digit Akun',
      docID: 'ID Dokumen',
      bankBranchCode: 'Kode cabang',
      accType: 'Tipe Akun',
      accName: 'Nama Akun',
      accNum: 'Nomor Akun',
      bankName: 'Nama Bank',
      swiftCode: 'Kode SWIFT',
      bankAddress: 'Alamat Bank',
      bankHolderAddress: 'Alamat Pemegang Akun',
      bankBeneficiaryName: 'Nama Bank Penerima',
      bankAccNum: 'Nomor Akun Bank',
    },
    keys: {
      SUBMIT: 'KIRIMKAN',
      FROM: 'DARI',
      UPDATE: 'MEMPERBARUI',
      TO: 'KE',
      DATE: 'TANGGAL PEMBUKAAN',
      pleaseSelect: 'Silahkan Pilih',
      amount: 'Jumlah',
      rebateAccount: 'Akun Rabat',
      comingSoon: 'Segera hadir',
      tradingHistory: 'RIWAYAT PERDAGANGAN',
      noData: 'Data tidak tersedia',
      NAME: 'NAMA',
      EMAIL: 'EMAIL',
      ACCTYPE: 'TIPE AKUN',
      PLATFORM: 'PLATFORM',
      BASECURRENCY: 'BASIS MATA UANG',
      DATERANGE: 'RENTANG TANGGAL',
      STATUS: 'STATUS',
      DEST: 'TUJUAN',
      ACCNUM: 'NOMOR AKUN',
      BALANCE: 'SALDO',
      PROFITLOST: 'LABA',
      MARGINLVL: 'TINGKAT MARJIN',
      ACCEQUITY: 'EKUITAS AKUN',
      CREDIT: 'KREDIT',
      NONE: 'Tidak ada',
      ALL: 'Semua',
      actualAmonut: 'Jumlah Aktual',
      currency: 'MATA UANG',
      method: 'METODE',
      REGISTRATION: 'TANGGAL PENDAFTARAN',
      FTD: 'TANGGAL FTD',
      CURRENTCAMPAIGN: 'PROMOSI SAAT INI',
      blacklisted: 'Masuk daftar hitam',
      FIRST_TRANS_IN: 'TRANSPERTAMA PADA TANGGAL',
      createTime: 'Waktu Kirim',
      lastUpdateTime: 'Waktu Diprose',
    },
    dateRange: {
      today: 'Hari ini',
      yesterday: 'Kemarin',
      lastSevenDays: '7 hari terakhir',
      lastThirtyDays: '30 hari terakhir',
      weekToDate: 'Minggu sampai saat ini',
      monthToDate: 'Bulan Sampai Saat Ini',
      quarterToDate: 'Kuartal sampai saat ini',
      previousWeek: 'Minggu lalu',
      previousMonth: 'Bulan lalu',
      previousQuarter: 'Kuartal lalu',
    },
    products: { forex: 'FOREX', commodities: 'KOMODITAS', indices: 'INDEKS', crypto: 'KRIPTO' },
    formValidation: {
      dynamicReq: '{dynamic} diperlukan',
      verCodeReq: 'Dibutuhkan kode verifikasi',
      alphanumericReq: 'Silaihkan isi dengan alfanumerik',
      answerReq: 'Silahkan pilih',
      sameAsPOA: 'Kode Pos',
      sameAsID: 'Entri harus sama dengan Kartu Identitas',
      notBlank: 'Kosongkan bila tidak ada',
      digits12WithoutDash: 'Silakan masukkan 12 digit, tanpa tanda "-".',
      enterAsPerId: 'Masukkan sesuai ID',
      numberReq: 'Silahkan isi dengan nomor',
      addressReq: 'Silahkan masukan alamat anda',
      suburbReq: 'Sialhkan masukan kota atau kabupaten anda',
      stateReq: 'Provinsi diperlukan',
      postcodeReq: 'Silahkan masukan kode pos',
      countryReq: 'Silahkan pilih negara tempat tinggal anda',
      AlphanumericOnlyNotSpace: 'Hanya alfanumerik yang diperbolehkan. Spasi tidak diizinkan.',
      AlphanumericOnly: 'Hanya huruf dan angka.',
      NumbersSymbol: 'Hanya angka dan simbol.',
      NumbersOnlyNotSpace: 'Hanya angka. Spasi tidak diizinkan.',
      NumbersOnly: 'Hanya angka.',
      LettersSymbol: 'Hanya huruf dan simbol.',
      LettersSymbolNotSpace: 'Hanya huruf dan simbol. Spasi tidak diizinkan.',
      LettersOnlyNotSpace: 'Hanya huruf. Spasi tidak diizinkan.',
      NotSpace: 'Spasi tidak diperbolehkan.',
    },
  },
  report: {
    title: { rebateReport: 'LAPORAN RABAT' },
    rebate: {
      column: {
        name: 'NAMA',
        accountNumber: 'NOMOR AKUN',
        accountType: 'TIPE AKUN',
        volume: 'Volume',
        rebate: 'RABAT',
        totalRebate: 'RABAT TOTAL',
        date: 'TANGGAL',
        instrument: 'INSTRUMEN',
      },
      dropdown: { allInstruments: 'Semua Instrumen', all: 'Semua', instruments: 'Instrumen' },
      others: {
        rebate: 'RABAT',
        instrumentsTraded: 'INSTRUMEN YANG DIPERDAGANGKAN',
        lots: 'Lot',
        totalRebate: 'TOTAL RABAT',
        rebates: 'Rabat',
      },
    },
  },
  ibReport: {
    header: 'LAPORAN IB',
    netFunding: 'PENDANAAN BERSIH',
    deposits: 'DEPOSIT / SETORAN',
    withdraw: 'PENARIKAN',
    openedAccs: 'AKUN DIBUKA',
    funding: 'PENDANAAN',
    withdrawals: 'PENARIKAN',
    appliedAmount: 'jumlah yang diterapkan',
    depositAmount: 'Jumlah Deposit',
    withdrawalAmount: 'Jumlah penarikan',
    timeLimitMessage:
      'Rentang maksimum untuk unduhan adalah 90 hari, mohon perbarui rentang tanggal yang baru',
    successInfo: 'BERHASIL DIUNDUH',
    download: 'UNDUH',
    comfirm: 'KONFIRMASI',
    defaultTimeMessage: 'Rentang maksimum pengunduhan adalah 90 hari',
  },
  lead: { header: 'LEAD', ibAccount: 'Akun IB', demoAccounts: 'AKUN DEMO' },
  pendingAccounts: {
    header: 'AKUN DITUNDA',
    errorDoc:
      'Pemohon berikut telah mengisi formulir aplikasi online namun belum memberikan bukti identitas (ID) untuk melengkapi akun mereka.',
    acceptedDocType: 'Bentuk tanda pengenal yang bisa diterima meliputi:',
    primaryDoc: 'Tanda Pengenal Utama',
    passport: 'Salinan paspor internasional yang masih berlaku (halaman bertanda tangan)',
    id: 'Salinan Kartu Tanda Penduduk (KTP) yang dikeluarkan oleh pemerintah',
    dl: 'Salinan Surat Izin Mengemudi (SIM) yang masih berlaku',
    address: 'Tanda Pengenal Tambahan',
    addressList: [
      'Salinan dari tagihan rumah tangga terkini (tidak boleh lebih dari 6 bulan)',
      'Salinan dari tagihan kartu kredit (tidak boleh lebih dari 6 bulan)',
      'Salinan dari rekening koran bank ( tidak boleh lebih dari 6 bulan)',
    ],
    tip: 'Dimohon untuk mengingatkan pemohon untuk menyediakan salinan dari SALAH SATU dokumen tanda pengenal utama yang diterima dan tanda pengenal tambahan ke',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'RIWAYAT TRANSAKSI',
    rebateHistory: 'Rabat',
    withdrawHistory: 'PENARIKAN',
    transferHistory: 'TRANSFER',
    cancelWithdraw: 'Apakah Anda ingin Membatalkan Permintaan Penarikan?',
    cancelError:
      'Penarikan Anda sedang diproses saat ini. Oleh karena itu, kami tidak dapat menerima permintaan pembatalan Anda. Untuk informasi lebih lanjut, Anda dapat menghubungi kami menggunakan fitur obrolan langsung.',
  },
  fileUploader: {
    supportedFormat: 'Jenis file yang didukung: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Jenis file yang didukung: {files}',
    maxSize: 'Ukuran file unggahan maksimum: {maxSize} MB',
    maxFileNum: 'Nomor file maksimum: {maxFileNum}',
    selectFile: 'Pilih File',
    maxFileSize: 'Ukuran berkas tidak boleh melebihi {maxSize}MB',
    sureToDelete: {
      title: 'Apakah Anda yakin?',
      content: 'Anda yakin ingin menghapus berkas yang diunggah?',
    },
  },
  transferHistory: {
    payStatus: {
      processing: 'Sedang diproses',
      paid: 'Terbayar',
      unpaid: 'Belum terbayar',
      rejected: 'Ditolak',
    },
  },
  referralLinks: {
    header: 'TAUTAN REFERAL',
    ibAcc: 'Akun IB',
    campLang: 'Bahasa Kampanye',
    cpy: 'SALIN',
    alreadyCPY: 'TERSALIN!',
    liveAcc: 'Akun Live',
    demoAcc: 'Akun Demo',
    homePage: 'Beranda',
    Share: 'Bagikan',
    subIbReferral: 'Referral IB Sub',
  },
  settings: { ibProfile: 'PROFIL IB', info: 'INFO', reg: 'TERDAFTAR PADA' },
  myProfile: {
    changeDetails:
      'Jika anda menginginkan perubahan pada detail profil yang ada, jangan ragu untuk menghubungi {mailTo}',
  },
  unfundedAcc: {
    header: 'AKUN BELUM DIDANAI',
    allActivationAccs: 'SEMUA AKUN AKTIVASI',
    neverFundedAccs: 'TIDAK PERNAH DIDANAI',
    prevFundedZero: 'SALDO NOL',
  },
  multiib: { level: 'LEVEL {level}', noib: 'Tidak ada sub-IB di bawah akun ini' },
  header: { bckToCP: 'KEMBALI KE PORTAL KLIEN', logout: 'KELUAR' },
  menu: {
    dashboard: 'DASBOR / PAPAN INSTRUMEN ',
    ibReport: 'LAPORAN IB',
    rebateReport: 'LAPORAN RABAT',
    accManagement: 'AKUN',
    iblevel: 'MULTI-LEVEL',
    ibAccs: 'AKUN DIAKTIFKAN',
    leads: 'LEAD',
    pendingAccs: 'AKUN DITUNDA',
    unfundedAccs: 'AKUN BELUM DIDANAI',
    payHistory: 'RIWAYAT TRANSAKSI',
    ibProfile: 'PROFIL IB',
    refLinks: 'TAUTAN REFERAL',
    contactUs: 'HUBUNGI KAMI',
    switchCP: 'GANTI KE PORTAL KLIEN',
  },
  footer: {
    riskWarn:
      'Peringatan Risiko: Perdagangan Contracts for Difference (CFD) melibatkan risiko tinggi terhadap modal anda, dan dapat berakhir dengan kerugian, anda sebaiknya hanya berdagang dengan menggunakan uang yang anda siap untuk hilang. Perdagangan CFD mungkin tidak cocok untuk semua investor, mohon pastikan bahwa anda memahami sepenuhnya risiko yang ada dan melakukan persiapan untuk mengelolanya. Mohon membaca dokumen Pengungkapan Risiko terkait dengan cermat.',
  },
  responseMsg: {
    410: 'Validasi parameter gagal',
    411: 'Kata kunci Salah',
    420: 'Pengguna tidak ditemukan',
    421: 'Setel Ulang Kata Sandi ditolak',
    431: 'Perhitungan rebate sedang dalam proses, mohon coba permintaan Anda lagi dalam beberapa waktu.',
    490: 'Harap jangan mengirimkan informasi kartu secara berganda',
    500: 'Terjadi kesalahan.',
    501: 'Permohonan gagal. Silakan coba lagi nanti.',
    505: 'Gagal mengunggah berkas',
    520: 'Gagal masuk. Silahkan coba lagi.',
    521: 'Seorang pengguna sudah masuk di perangkat ini!',
    522: 'Silakan login lagi',
    523: 'Silakan login lagi',
    524: 'Mohon maaf, alamat email anda tidak dikenali',
    525: 'Username atau password salah',
    527: 'Pengguna bukan seorang IB',
    528: 'Pengguna tidak ditemukan',
    529: 'Username atau password salah',
    530: 'Sesi login tidak valid',
    540: 'Akun trading anda tidak dapat ditemukan',
    541: 'Akun Rabat tidak dapat ditemukan',
    542: 'Anda tidak diizinkan untuk membuka akun tambahan',
    544: 'Anda tidak diperbolehkan mendaftarkan akun tambahan hingga Bukti Identitas Anda diverifikasi',
    550: 'Nomor rekening salah',
    551: 'Saldo anda tidak mencukupi',
    553: 'anda tidak dapat menarik / mentransfer karena kredit di akun anda. Harap hubungi {email} untuk bantuan lebih lanjut',
    554: 'Jumlah yang diminta nol atau null',
    557: 'Permintaan penarikan Anda hanya dapat diproses sebagian karena kesalahan yang tidak terduga. Harap periksa status penarikan Anda di Riwayat Transaksi.',
    562: 'Kami tidak dapat memproses pembayaran anda, coba lagi. Jika tetap terjadi masalah, silakan hubungi kami melalui obrolan langsung, atau email {email}',
    564: 'Negara tidak sesuai dengan portal pembayaran',
    565: 'Kode Bank Salah',
    566: 'Metode pembayaran tidak ditemukan',
    581: 'anda telah berhasil mengikuti promosi. Harap dicatat bahwa anda harus melakukan deposit untuk menarik dana.',
    582: 'Karena Anda telah mengikuti program promosi dan memiliki posisi terbuka, kami tidak dapat memproses permintaan penarikan Anda',
    584: 'Anda tidak memiliki rabat yang berlaku',
    590: 'Akun transfer keluar tidak dapat ditemukan',
    591: 'Akun transfer masuk tidak dapat ditemukan',
    593: 'Kami tidak dapat memproses transfer dana Anda dari akun yang dipilih karena akun Anda mengandung kredit.',
    594: 'Transfer dana dari akun ini tidak diizinkan',
    595: 'Karena Anda telah mengikuti program promosi kami, kami tidak dapat memproses permintaan transfer Anda',
    647: 'Kode verifikasi tidak benar',
    1101: '10 akun sudah dimiliki, tidak dapat menambah.',
    1102: 'Tidak dapat diaktifkan, akun telah dibuat sebagai akun live atau demo.',
    1103: 'Akun telah dinonaktifkan',
    1202: 'Verifikasi kedaluwarsa, silakan coba lagi',
    1204: 'Silakan coba dengan alamat email lainnya',
    1208: 'Kode verifikasi tidak dapat dikirim ulang',
    1209: 'Kesalahan pengiriman kode verifikasi',
    session_timeout: 'Sesi berakhir, mengarahkan ke laman login',
  },
  verificationStatus: {
    title: { default: 'Hampir Selesai', LV3: 'Selamat Datang' },
    dueToAML:
      'Anda siap untuk mengawali trading segera! Bantu kami mengamankan akun Anda dengan memverifikasi informasi pribadi Anda.',
    accOpeningVerification: {
      title: 'Buat Akun Live',
      content: 'Buat Akun Live untuk mulai mendanai akun Anda.',
    },
    identityVerification: {
      title: 'Verifikasi Identitas',
      content: 'Kirim dan verifikasi bukti identitas Anda untuk mulai trading.',
    },
    poaVerification: {
      title: 'Verifikasi Alamat',
      content: 'Bukti Alamat perlu diverifikasi sebelum Anda bisa melakukan penarikan dana.',
    },
    kyc: {
      depositNow: 'Deposit Sekarang',
      withdrawNow: 'Tarik Sekarang',
      createNow: 'Buat sekarang',
      start: 'Mulai',
      complete: 'Tuntas',
      pending: 'Tertunda',
      rejected: 'Ditolak',
      resubmit: 'Kirim Ulang',
    },
    resubmitNote: 'Dokumen Anda mungkin kurang lengkap atau salah. Harap kirim lagi.',
    rejectedNote: 'Ada pertanyaan? Harap hubungi kami via {liveChat}',
    liveChat: 'Chat Langsung',
    uploadTemplate: {
      idGuideTitle: 'Panduan Dokumen Identitas',
      uploadGuideTitle: 'Unggah Panduan',
      desc1: 'Diterbitkan Pemerintah',
      desc2: 'Dokumen asli ukuran penuh, tanpa diedit',
      desc3: 'Tempatkan dokumen pada latar belakang satu warna',
      desc4: 'Gambar mudah dibaca, cukup terang, dan berwarna',
      desc5: 'Bukan gambar hitam putih',
      desc6: 'Dokumen tidak diedit atau kedaluwarsa',
      bottomDesc:
        'Berkas yang diunggah dapat berupa jpg, png, atau pdf, dan ukuran berkas tidak boleh lebih dari 5MB',
    },
  },
  register: {
    progressStatus: {
      title: 'Perkembangan verifikasi',
      estimatedTimeTitle: 'perkiraan waktu',
      min: 'Menit',
    },
    tabs: {
      personalDetails: 'Informasi Pribadi',
      moreAboutYou: 'Lebih terperinci tentang Anda',
      accountConfig: 'Konfigurasi Akun',
      confirmYourId: 'Konfirmasi Identitas Anda',
      fundYourAcc: 'Danai Akun Anda',
      personalInformation: 'Informasi Pribadi',
      createLiveAccount: 'Buat Akun Live',
      financialInfo: 'Info Keuangan',
      iDProof: 'Bukti Identitas',
      addressProof: 'Bukti Alamat',
    },
    btn: {
      next: 'BERIKUTNYA',
      back: 'KEMBALI',
      fundnow: 'DANAI SEKARANG',
      upload: 'Unggah',
      done: 'SELESAI',
      bTCP: 'Kembali ke Portal Klien',
      proceedToUpload: 'Lanjut Mengunggah',
      completeID: 'Tuntaskan Verifikasi Identitas',
      completePOA: 'Tuntaskan Verifikasi Bukti Alamat',
      depositViaOther: 'Deposit Dengan Cara Lain',
      withdrawViaOther: 'Penarikan melalui saluran lain',
    },
    authority: {
      dialog1: 'anda harus memiliki akun live untuk mengakses bagian portal klien ini.',
      dialog2:
        'Buka akun live sekarang untuk membuka akses penuh ke portal klien anda, yang mencakup berbagai alat perdagangan, promosi, dan banyak lagi.',
      openLiveAcc: 'buka akun live',
    },
    demo: {
      congrad: 'Selamat!',
      title: 'Akun DEMO MT {platform}  60 Hari anda sekarang sudah aktif',
      title2: 'Detail Akun DEMO anda',
      li: { login: 'LOGIN', srv: 'Server', expDate: 'Tanggal kedaluwarsa' },
      emailSent: 'Detail login juga telah dikirim ke email anda yang terdaftar.',
      dl: 'Unduh Platform Trading MT {platform}anda',
      improveQ: 'Apakah anda ingin meningkatkan trading anda？',
      improveWay:
        'Cukup, buka akun live hanya dalam beberapa menit untuk membuka berbagai alat trading dan promosi eksklusif.',
      proTrade: 'Laporan pemasaran profesional',
      customerService: '24/7 Layanan pelanggan satu-satu',
      competitivePromotionals: 'Promosi kompetitif',
      secure: 'Transaksi 3D Secure',
      openLiveAcc: 'buka akun live',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Informasi Pribadi',
        userTitle: 'Gelar',
        firstName: 'Nama Depan',
        lastName: 'Nama Belakang',
        email: 'E-mail',
        dob: 'Tanggal Lahir',
        idType: 'Tipe Identifikasi',
        referral: 'Apakah anda pernah dirujuk oleh seseorang？ (opsional)',
        middleName: 'Nama Tengah',
        nationality: 'Kewarganegaraan',
        phoneNum: 'Nomor Telepon',
        date: 'Hari',
        month: 'Bulan',
        year: 'Tahun',
        placeOfBirth: 'Tempat Lahir',
        idNum: 'Nomor identifikasi',
        passportNum: 'Nomor paspor',
        driverLicenseNum: 'Nomor Surat Izin Mengemudi',
        titleDropdown: { mr: 'Mr', mrs: 'Mrs', ms: 'Ms', miss: 'Miss', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Paspor',
          idCard: 'Kartu Tanda Penduduk',
          driverLicense: 'SIM',
        },
      },
      page2: {
        pageTitle: 'ALAMAT TEMPAT TINGGAL UTAMA',
        mainResidency: 'Negara Domisili Utama',
        streetNumber: 'Nomor jalan',
        streetName: 'Nama jalan',
        address: 'Alamat',
        provinceOrState: 'Provinsi / Negara Bagian',
        cityOrSuburb: 'Kota / Kabupaten',
        postcode: 'Kode Pos',
        usQuestion:
          'Apakah anda Warga Negara / Penduduk AS untuk tujuan pajak atau apakah anda memiliki tautan / ikatan dengan AS (mis. Rekening Bank, dll.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'STATUS PEKERJAAN DAN RINCIAN KEUANGAN',
      pageTitle2: 'Pengetahuan dan Pengalaman Trading',
      complianceExplain:
        'Pertanyaan-pertanyaan berikut dikumpulkan untuk memenuhi persyaratan AML / CTF.',
    },
    accountConfig: {
      pageTitle: 'Konfigurasi Akun',
      chooseTradingPlatform: 'Pilih Platform Trading',
      chooseAccType: 'Pilih Tipe Akun',
      chooseAccCurrency: 'Pilih Mata Uang Akun',
    },
    confirmYourId: {
      pageTitle: 'Konfirmasi Identitas Anda',
      description:
        'Sebagai broker forex teregulasi, kami diwajibkan untuk memverifikasi identitas anda. Harap unggah salah satu dokumen berikut untuk verifikasi Bukti KTP dan Bukti Alamat anda. Atau, anda dapat mengirimkan dokumen verifikasi anda melalui email ke {mailTo}',
      poidTitle: 'Bukti Identitas',
      porTitle: 'Bukti Tempat Tinggal',
      uploadDescription: 'Pilih tipe dokumen dan unggah yang sesuai',
      note: 'PENTING: Nama di dokumen HARUS menunjukkan nama lengkap anda dengan jelas.',
      pending: { upload: 'Unggahan Tertunda', verification: 'Verifikasi Tertunda' },
      poidTypes: {
        photoId: 'Foto Dokumen Identitas',
        passport: 'Paspor',
        drivingLicence: 'Surat Izin Mengemudi',
      },
      porTypes:
        '<li> Tagihan Utilitas </li>\n      <li> Laporan Bank </li>\n      <li> Sertifikat Tempat Tinggal </li>',
    },
    finishPage: {
      title: '{individualUserName}, terima kasih telah mendaftar untuk akun live {platformName}!',
      contentDefault:
        'Kami telah menerima aplikasi anda dan menyiapkan akun live anda <b> {account} </b>. <br /> anda akan segera menerima email di <b> {email} </b> yang berisi detail login anda dan link untuk melihat status akun anda. <br />',
      contentWithoutId3WithAccount:
        'Akun trading live anda saat ini sedang diproses dan anda akan segera menerima email yang berisi informasi lebih lanjut. <br />\n      Untuk mempercepat pemrosesan akun live anda, anda dapat mendanai akun anda sekarang dan menerima prioritas pembukaan. <br />',
      contentWithoutId3WithoutAccount:
        'Akun trading live anda saat ini sedang diproses dan anda akan segera menerima email yang berisi informasi lebih lanjut. <br />',
    },
    accOpeningVerification: {
      accOpenVer: 'Verifikasi Pembukaan Akun',
      personalInformation: {
        narrative: 'Informasi pribadi Anda diperlukan untuk membuka Akun Live Anda',
        firstName: 'Nama Depan',
        middleName: 'Nama Tengah',
        lastName: 'Nama Belakang',
        gender: 'Jenis Kelamin',
        genderList: { male: 'Laki-laki', female: 'Perempuan', other: 'Lainnya' },
        dob: 'Tanggal Lahir',
        day: 'Tanggal',
        month: 'Bulan',
        year: 'Tahun',
        mobile: 'Nomor Ponsel',
        countryName: 'Negara Tempat Tinggal',
        nationality: 'Kewarganegaraan',
      },
      createLiveAccount: {
        narrative:
          'Tuntaskan beberapa langkah untuk membuka akun dan mulai mendanainya. Info selengkapnya tentang tipe akun kami ada di <a href="{link}" target="_blank" alt>sini</a>.',
        choose: 'Pilih {dynamic}',
        tradingPlatform: 'Platform Trading',
        accountType: 'Tipe Akun',
        accountCurrency: 'Mata Uang Akun',
        islamicTooltip:
          '{platform} mungkin meminta Anda untuk memberikan dokumen pendukung guna memverifikasi bahwa Anda beragama Islam. {platform} berhak mengubah konfigurasi akun Anda bila verifikasi gagal.',
        successInfo: {
          title: 'Akun Trading Dibuat',
          content:
            'Selamat! Anda berhasil menuntaskan proses pembukaan rekening. <br /> Untuk mulai trading, lakukan deposit sekarang dan tuntaskan verifikasi Identitas Anda.',
        },
        successInfo2: {
          title: 'Akun Trading Dibuat',
          content:
            'Isi informasi profil keuangan Anda untuk memulai langkah selanjutnya dalam proses verifikasi akun Anda',
          start: 'Mulai',
        },
        generalInfo: {
          title: 'Pengajuan Anda Sedang Ditinjau.',
          content:
            'Terima kasih telah membuka akun di {platform}. Anda dapat mulai mendanai akun setelah pengajuan Anda disetujui.',
        },
        rejectedInfo: {
          title: 'Ditolak',
          content:
            'Dengan berat hati kami ingin menyampaikan bahwa permintaan Anda untuk membuka akun {platform} telah ditolak.',
        },
      },
    },
    identityVerification: {
      title: 'Verifikasi Identitas',
      subTitle: 'Informasi Identitas',
      narrative: 'Isi selengkapnya',
      firstName: 'Nama Depan',
      middleName: 'Nama Tengah',
      lastName: 'Nama Belakang',
      nationality: 'Kewarganegaraan',
      idType: 'Jenis Kartu Identitas',
      idSerialNumber: 'Nomor Kartu Identitas',
      passportNum: 'Nomor paspor',
      driverLicenseNum: 'Nomor Surat Izin Mengemudi',
      upload: {
        title: 'Unggah Dokumen',
        desc: 'Pastikan dokumen menampilkan foto Anda, nama lengkap, tanggal lahir, dan tanggal penerbitan.',
        guideline: 'Unggah Panduan',
        positive: 'Unggah Positif',
        signaturePage: 'Unggah halaman Bertanda Tangan',
      },
      idTypeDropdown: {
        passport: 'Paspor',
        idCard: 'Kartu Tanda Penduduk',
        driverLicense: 'SIM',
        other: 'Lainnya',
      },
      additionalInfo: {
        title: 'Info Tambahan Dibutuhkan',
        content: 'Verifikasi identitas Anda untuk mulai trading',
      },
      successInfo: {
        title: 'Verifikasi Identitas Tuntas',
        content:
          'Selamat! Identitas Anda telah diverifikasi. Sekarang Anda bisa mulai trading di MT4/MT5 atau aplikasi {platform} bila Anda telah melakukan deposit.',
      },
      generalInfo: {
        title: 'Pengajuan Anda Sedang Ditinjau.',
        content:
          'Terima kasih telah membuka akun di {platform}. Anda bisa mulai trading setelah informasi identitas Anda diverifikasi.',
      },
      rejectedInfo: {
        title: 'Ditolak',
        content:
          'Dengan berat hati kami ingin menyampaikan bahwa permintaan Anda untuk membuka akun {platform} telah ditolak.',
      },
      identifyingRequiredInfo: {
        title: 'Info Tambahan Dibutuhkan',
        content:
          'Beberapa dokumen identitas Anda mungkin kurang lengkap atau salah. Harap unggah ulang untuk melanjutkan.',
      },
      streetNumber: 'Nomor jalan',
      streetName: 'Nama jalan',
    },
    poaVerification: {
      proofOfAddressVer: 'Verifikasi Bukti Alamat',
      subTitle: 'Informasi Alamat',
      narrative: 'Isi selengkapnya',
      dueToAML:
        'Karena ketentuan APU, alamat Anda harus diverifikasi sebelum dapat melakukan penarikan dana.',
      nationality: 'Kewarganegaraan',
      country: 'Negara Tempat Tinggal',
      address: 'Alamat diperlukan',
      postalCode: 'Kota/Kabupaten',
      citySuburb: 'Provinsi',
      city: 'Kota',
      provinceState: 'Provinsi',
      upload: {
        title: 'Sertifikat Bukti Alamat',
        inst1: 'Tagihan utilitas (listrik, air, gas)',
        inst2: 'Tagihan kartu kredit atau rekening koran bank',
        desc: 'Informasi Bukti Alamat untuk penarikan dana harus diterbitkan dalam {months} bulan terakhir',
        guideline: 'Unggah Panduan',
        uploadFilesPhoto: 'Unggah Berkas/Foto',
      },
      additionalInfo: {
        title: 'Info Tambahan Dibutuhkan',
        content: 'Bukti Alamat perlu diverifikasi sebelum Anda bisa melakukan penarikan dana.',
      },
      successInfo: {
        title: 'Bukti Alamat Diverifikasi',
        content:
          'Selamat! Bukti Alamat Anda telah diverifikasi. Sekarang Anda bisa melakukan penarikan dana.',
      },
      generalInfo: {
        title: 'Pengajuan Anda Sedang Ditinjau.',
        content:
          'Terima kasih telah berdagang dengan {platform}. Anda dapat memulai proses penarikan setelah pengajuan Anda disetujui.',
      },
      identifyingRequiredInfo: {
        title: 'Info Tambahan Dibutuhkan',
        content:
          'Beberapa dokumen identitas Anda mungkin kurang lengkap atau salah. Harap unggah ulang untuk melanjutkan.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'Informasi Finansial Anda',
        subTitle: 'Pekerjaan dan Data Keuangan',
        subTitle2: 'Pengetahuan dan Pengalaman Trading',
        employmentStatus: 'Status Pekerjaan',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title} Per Minggu',
        estimatedAnnualIncome: 'Estimasi Penghasilan Tahunan',
        estimatedSavingsAndInvestments: 'Perkiraan Tabungan dan Investasi',
        estimatedIntendedDeposit: 'Estimasi Deposit yang Direncanakan',
        sourceOfFunds: 'Sumber Dana',
        numberOfTrades: 'Frekuensi Trading',
        tradingAmount: 'Nilai Trading',
      },
      iDProof: {
        ver: 'Unggah Bukti Identitas',
        subTitle: 'Informasi Identitas',
        narrative: 'Isi selengkapnya',
      },
      addressProof: {
        ver: 'Unggah Bukti Alamat',
        subTitle: 'Informasi Alamat',
        narrative: 'Isi selengkapnya',
        upload: {
          title: 'Dokumen Bukti Alamat yang Dapat Diterima',
          inst1: 'Tagihan utilitas (listrik, air, gas)',
          inst2: 'Tagihan kartu kredit atau rekening koran bank',
          desc: 'Informasi Bukti Alamat untuk penarikan dana harus diterbitkan dalam {months} bulan terakhir',
          guideline: 'Unggah Panduan',
          uploadFilesPhoto: 'Unggah Berkas/Foto',
        },
      },
      successInfo: {
        title: 'Autentikasi Bank Tuntas',
        content: 'Selamat! Anda telah berhasil memverifikasi data perbankan Anda.',
      },
      generalInfo: {
        title: 'Pengajuan Anda Sedang Ditinjau.',
        content:
          'Dokumen Anda untuk transfer bank masih dalam proses peninjauan. Jika Anda ingin memulai trading sekarang, Anda masih dapat melakukan deposit melalui saluran lain.',
      },
      rejectedInfo: {
        title: 'Ditolak',
        content: 'Permintaan Anda untuk melakukan deposit melalui transfer bank telah ditolak.',
      },
      identifyingRequiredInfo: {
        title: 'Info Tambahan Dibutuhkan',
        content:
          'Beberapa dokumen identitas Anda mungkin kurang lengkap atau salah. Harap unggah ulang untuk melanjutkan.',
      },
    },
  },
  ibProfile: {
    title: 'PROFIL IB',
    subTitle: 'IB Bersama',
    sharedIbList: {
      sharedIbAccount: 'Akun IB Bersama',
      maxSharedIbAccount: 'Maksimal 10 akun IB Bersama',
      add: 'Tambah',
      accountHistory: 'Riwayat Akun IB Bersama',
      setting: 'Pengaturan',
      delete: 'Hapus',
      enable: 'Aktifkan',
      disable: 'Nonaktifkan',
      enabled: 'Diaktifkan',
      disabled: 'Dinonaktifkan',
      sharedAccountEnabled: 'Akun IB Bersama telah diaktifkan',
      sharedAccountDisabled: 'Akun IB Bersama telah dinonaktifkan',
      sharedAccountCopied: 'Akun IB bersama disalin',
      deleteTips:
        'Akun IB Bersama tidak dapat login setelah dihapus, apakah Anda ingin menghapusnya?',
      sharedAccountDeleted: 'Akun IB Bersama telah dihapus',
      copyTips: 'Akun IB bersama disalin',
      remark: 'Catatan',
      cancel: 'Batalkan',
      accountData: 'Data akun',
      IBData: 'Data laporan IB',
      fundOperations: 'Perizinan pendanaan',
      resetPassword: 'Atur ulang password',
      accountHistoryBtn: 'Riwayat akun bersama',
    },
    updateSharedIb: {
      addNewSharedIb: 'Tambahkan akun IB Bersama baru',
      editSharedIb: 'Edit akun IB Bersama',
      maxSharedIbAccountError: '10 akun sudah dimiliki, tidak dapat menambah.',
      email: 'Alamat Email',
      emailValidate: 'Silakan masukan alamat email yang valid',
      emailError: 'Email telah digunakan, silakan gunakan yang lain',
      password: 'Password',
      passwordValidate: 'Silakan masukan password',
      passwordValidateNum: '8-16 karakter',
      passwordValidateTips:
        'Gabungan huruf kapital dan huruf kecil (A-Z, a-z), angka (0-9), dan karakter khusus (contoh: !@#S%^&)',
      passwordValidateWarning:
        '8-16 karakter,Gabungan huruf kapital dan huruf kecil (A-Z, a-z), angka (0-9), dan karakter khusus (contoh: !@#S%^&)',
      sharedIbAccountPermission: 'Perijinan Akun IB Bersama',
      remark: 'Catatan',
      remarkTips: 'Anda dapat merekam tujuan akun atau pemegang akun',
      status: 'Status',
      cancel: 'Batalkan',
      confirm: 'Konfirmasi',
      disabled: 'Dinonaktifkan',
      enabled: 'Diaktifkan',
      resetPassword: 'Atur ulang password',
      emailPlaceholder: 'Silakan masukkan email',
      addSuccess: 'Akun bersama dibuat',
      permissionIdsTips: 'Anda dapat memberikan izin berikut ini pada akun bersama atau tidak:',
      permissionIdsFirst:
        '<b>Data akun</b>: Melihat halaman data akun (Akun IB, Akun yang Baru Dibuka). Data akan disembunyikan jika izin tidak diizinkan.',
      permissionIdsSecond:
        '<b>Data laporan IB</b>: Melihat halaman data IB (Dasbor, IB Multi-Level - Akun). Data akan disembunyikan jika tidak diizinkan.',
      permissionIdsThird:
        '<b>Perizinan pendanaan</b>: Pengoperasian dana IB (Pengajuan Rebate, Transfer Rebate, Penarikan Rebate), Lihat rebate (Laporan rebate, Saldo, dan catatan Dana). Pengoperasian tidak dapat dilakukan jika izin tidak diizinkan.',
      newPassword: 'Silakan masukkan password baru',
      newPassowrdReset: 'Silakan masukkan password baru',
      passwordSame: 'Password baru tidak boleh sama dengan password lama',
      resetPasswordSuccess: 'Pengaturan ulang password berhasil',
      enterNewPassword: 'Silakan masukkan password baru',
      changePasswordTips:
        'Password lama akan menjadi tidak valid dan diatur ulang ke password baru',
      fundsChangeTips:
        'Setelah perizinan dana disetujui, akun IB Bersama dapat mentransfer dan melakukan withdraw saldo akun IB utama Anda. Apakah Anda ingin memperbolehkan hal tersebut?',
      editSuccess: 'Berhasil diubah',
    },
    sharedIbHistory: {
      allHistory: 'Semua riwayat telah ditunjukkan',
      lastUpdate: 'Diperbarui terakhir kali',
      refreshTips: 'Riwayat diperbarui',
      refresh: 'Refresh',
      chooseAccount: 'Pilih Akun',
      device: 'Perangkat',
      login: 'Login ',
      commission: 'Ajukan Rabat',
      withdraw: 'Withdraw Rabat',
      transfer: 'Transfer Rabat',
      fromTo: 'Dari {fromAccount} ke {toAccount}, jumlah {total}',
      viewMore: 'Lihat selengkapnya',
    },
    npPermissionTips: 'Tidak ada ijin untuk akun ini',
  },
  agreement: {
    title: 'Konfirmasi Perjanjian IB',
    rebateAccount: 'Akun rebate',
    description:
      'Dengan mengklik Setuju, Anda mengonfirmasi bahwa Anda telah membaca, memahami, dan menyetujui semua informasi, syarat dan ketentuan layanan dalam perjanjian',
    agree: 'Setuju',
    signed: 'Akun rebate {accountID}. Ditandatangani pada {signedTime}',
    IBAgreement: 'Perjanjian IB',
    confirmAgreement: 'Silakan hubungi Manajer IB untuk mengonfirmasi perjanjian IB',
  },
};
